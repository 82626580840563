import HystModal from "./hystmodallib";
import "element-closest-polyfill";

global.HystModal = HystModal;

const myModal = new HystModal({
	// for dynamic init() of modals
	// linkAttributeName: false,
	catchFocus: false,
	closeOnEsc: true,
	backscroll: true,
	closeOnOverlay: true,
	closeOnEsc: true,
	beforeOpen: function (modal) {
		// console.log('Message before opening the modal');
		// console.log(modal); //modal window object
	},
	afterClose: function (modal) {
		// console.log('Message after modal has closed');
		// console.log(modal); //modal window object

		//If Youtube video inside Modal, close it on modal closing
		let videoframe = modal.openedWindow.querySelector("iframe");
		if (videoframe) {
			videoframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
		}
	},
});

// плавне закриття модального вікна по кліку на посилання всередині модалки.

document.addEventListener("DOMContentLoaded", function () {
	document.querySelectorAll('.hystmodal a[href^="./#"]').forEach((anchor) => {

		anchor.addEventListener("click", function (e) {
			
			// e.preventDefault(); // Запобігає стандартній поведінці браузера
			const target = this.getAttribute("href");
			if (target === "#" || !target) {
				myModal.close();
				return;
			}
			// Закриваємо модалку
			myModal.close();
			// прибираємо ./ в href="./#home"
			// const slicedtarget =target.slice(2)
			// Затримка, щоб модалка встигла закритися, і тільки потім здійснити перехід
			setTimeout(() => {
				document.querySelector(slicedtarget)?.scrollIntoView({
					behavior: "smooth",
				});
			}, 300); // 300 мс - час, протягом якого модалка закривається
		});
	});
});
